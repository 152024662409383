import React from 'react';

export default function NameAndHeadshot() {

  return (
		<div id="first-section">
			<div id="name-and-headshot">
				<div id="name-wrapper">
					<h1 className="name">hello!</h1>
					<h1 className="name">I am</h1>
					<h1 className="name" id="me">Laura Nemeth</h1>
				</div>
				<p>
					Let's collaborate on beautiful websites, apps, and more! I am
					passionate about the longevity of code, the accessibility of
					information, and the many mysteries of the world wide web.
				</p>
			</div>
		</div>
	);
};